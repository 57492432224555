import React, { useState } from "react";
import "../../Styles/acceptance.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressButton from './ProgressButton';
import {steps} from '../data/Steps'

const SubmissionAcceptance = () => {
  const [formData, setFormData] = useState({
    title: "",
    authorDetails: "",
    abstract: "",
    graphicalAbstract: null,
    introduction: "",
    introductionImage: null,
    results: {
      heading: "",
      rationale: "",
      methodology: "",
      interpretationLeft: "",
      interpretationRight: "",
    },
    links: "",
    discussion: "",
    acknowledgement: "",
    videoUpload: null,
    references: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (event, fieldName) => {
    const file = event.target.files[0];
    setFormData({ ...formData, [fieldName]: file ? file : null });
  };

  const handleFileDiscard = (fieldName) => {
    setFormData({ ...formData, [fieldName]: null });
  };

  const [progressStatus, setProgressStatus] = useState({
    currentStep: 1,
    completedSteps: [0, 1],
  });


  const getButtonClass = (index) => {
    if (index < progressStatus.currentStep) return "completed";
    if (index === progressStatus.currentStep) return "in-progress";
    return "disabled";
  };

  const handleSaveDraft = () => {
    toast.info("Draft saved!");
    console.log("Draft saved:", formData);
  };

  const handleSubmit = () => {
    toast.success("Form submitted!");
    console.log("Form submitted:", formData);
  };

  return (
    <div className="progress-banner-container">
      <ProgressButton
        steps={steps}
        currentStep={progressStatus.currentStep}
        getButtonClass={getButtonClass}
      />

<form className="acceptance-main-container">
      <h1 className="title">Title</h1>
      <input
        type="text"
        name="title"
        placeholder="Enter the title..."
        value={formData.title}
        onChange={handleInputChange}
      />

      <section className="author-details">
        <h2>Author Details</h2>
        <textarea
          name="authorDetails"
          placeholder="Enter author details..."
          value={formData.authorDetails}
          onChange={handleInputChange}
        />
      </section>

      <section className="abstract">
        <h2>Abstract</h2>
        <textarea
          name="abstract"
          maxLength={1000}
          placeholder="Write your abstract here (max 1000 words)..."
          value={formData.abstract}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
        <div className="word-count">{formData.abstract.split(" ").length}/1000</div>
      </section>

      <section className="graphical-abstract">
        <h2>Graphical Abstract</h2>
        <div className="upload-preview">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "graphicalAbstract")}
          />
          {formData.graphicalAbstract && (
            <div className="preview">
              <img
                src={URL.createObjectURL(formData.graphicalAbstract)}
                alt="Graphical Abstract Preview"
              />
              <button
                type="button"
                onClick={() => handleFileDiscard("graphicalAbstract")}
              >
                {formData.graphicalAbstract.name} <span>&#10005;</span>
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="introduction">
        <h2>Introduction</h2>
        <div className="introduction-content">
          <textarea
            name="introduction"
            placeholder="Write introduction here..."
            value={formData.introduction}
            onChange={handleInputChange}
          />
          <div className="file-upload">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileUpload(e, "introductionImage")}
            />
            {formData.introductionImage && (
              <div className="preview">
                <img
                  src={URL.createObjectURL(formData.introductionImage)}
                  alt="Introduction Preview"
                />
                <button
                  type="button"
                  onClick={() => handleFileDiscard("introductionImage")}
                >
                  {formData.introductionImage.name} <span>&#10005;</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="results-section">
        <h2>Results Section</h2>
        <div className="result-contents-section">
          <div className="left-fields">
            <input
              type="text"
              name="results.heading"
              placeholder="Result Heading"
              value={formData.results.heading}
              onChange={handleInputChange}
            />
            <textarea
              name="results.rationale"
              placeholder="Rationale"
              value={formData.results.rationale}
              onChange={handleInputChange}
            />
            <textarea
              name="results.methodology"
              placeholder="Methodology"
              value={formData.results.methodology}
              onChange={handleInputChange}
            />
            <textarea
              name="results.interpretationLeft"
              placeholder="Results and Interpretation"
              value={formData.results.interpretationLeft}
              onChange={handleInputChange}
            />
          </div>
          <div className="right-fields">
            <textarea
              name="results.interpretationRight"
              placeholder="Results and Interpretation"
              value={formData.results.interpretationRight}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="linker-section">
          <input
            type="text"
            name="links"
            placeholder="Add clickable links here..."
            value={formData.links}
            onChange={handleInputChange}
          />
        </div>
      </section>

      <section className="discussion">
        <h2>Discussion</h2>
        <textarea
          name="discussion"
          placeholder="Write discussion here..."
          value={formData.discussion}
          onChange={handleInputChange}
        />
      </section>

      <section className="acknowledgement">
        <h2>Acknowledgement</h2>
        <textarea
          name="acknowledgement"
          placeholder="Write acknowledgements here..."
          value={formData.acknowledgement}
          onChange={handleInputChange}
        />
      </section>

      <section className="video-upload">
        <h2>Video Upload</h2>
        <div className="upload-preview">
          <input
            type="file"
            accept="video/*"
            onChange={(e) => handleFileUpload(e, "videoUpload")}
          />
          {formData.videoUpload && (
            <div className="preview">
              <video src={URL.createObjectURL(formData.videoUpload)} controls />
              <button
                type="button"
                onClick={() => handleFileDiscard("videoUpload")}
              >
                {formData.videoUpload.name} <span>&#10005;</span>
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="reference">
        <h2>References</h2>
        <textarea
          name="references"
          placeholder="Add references here..."
          value={formData.references}
          onChange={handleInputChange}
        />
      </section>
    </form>

      <div className="form-footer">
        <button className="save-draft-button" onClick={handleSaveDraft}>
          Save as Draft
        </button>
        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SubmissionAcceptance;
