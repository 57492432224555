import React from 'react'
import { Outlet } from 'react-router-dom'
import AccountSideNavbar from './AccountSideBar'

const AccountDashboard = () => {
  return (
    <div className='policy-container'>
      <AccountSideNavbar/>
      <Outlet/>
      
    </div>
  )
}

export default AccountDashboard