// import React, { useEffect, useMemo, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import countryCodes from "../data/CountryCodes";
// import countries from "../data/Countries";
// import { changeTitle } from "../utils/ChangeTitle";

// const SignUpForm = ({ onSubmit }) => {
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [country, setCountry] = useState("");
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [educationLevel, setEducationLevel] = useState("");
//   const [affiliation, setAffiliation] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   useEffect(() => {
//     changeTitle("Signup | Scitome");
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (
//       firstName &&
//       lastName &&
//       country &&
//       email &&
//       phoneNumber &&
//       educationLevel &&
//       password === confirmPassword
//     ) {
//       onSubmit({
//         firstName,
//         lastName,
//         country,
//         email,
//         phoneNumber,
//         educationLevel,
//         affiliation,
//         password,
//       });
//     } else {
//       toast.error("Please fill in all fields and make sure passwords match.");
//     }
//   };

//   const countryOptions = useMemo(
//     () =>
//       countries.map((country) => (
//         <option value={country} key={country}>
//           {country}
//         </option>
//       )),
//     []
//   );

//   const countryCodeOptions = useMemo(
//     () =>
//       countryCodes.map(({ name, code, short }) => (
//         <option key={short} value={code}>
//           {name} ({code})
//         </option>
//       )),
//     []
//   );

//   return (
//     <div className="modal-signup-container">
//       <h2>Create an account</h2>
//       <p className="text-desc">Enter your email to sign up for this app</p>
//       <form className="signupmodal" onSubmit={handleSubmit}>
//         <div className="group">
//           <div className="input-group">
//             <label>
//               First Name <span className="required">*</span>
//             </label>
//             <input
//               type="text"
//               value={firstName}
//               onChange={(e) => setFirstName(e.target.value)}
//               required
//             />
//           </div>

//           <div className="input-group">
//             <label>
//               Last Name <span className="required">*</span>
//             </label>
//             <input
//               type="text"
//               value={lastName}
//               onChange={(e) => setLastName(e.target.value)}
//               required
//             />
//           </div>

//           <div className="input-group">
//             <label>
//               Country of Residence <span className="required">*</span>
//             </label>
//             <select
//               className="select-dropdown"
//               value={country}
//               onChange={(e) => setCountry(e.target.value)}
//               required
//             >
//               <option value="">Select country</option>
//               {countryOptions}
//             </select>
//           </div>

//           <div className="input-group">
//             <label>
//               Email ID <span className="required">*</span>
//             </label>
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </div>

//           <div className="input-group">
//             <label>
//               Phone Number <span className="required">*</span>
//             </label>
//             <div className="phone-input-group">
//               <select
//                 className="select-dropdown country-code"
//                 value={country}
//                 onChange={(e) => setCountry(e.target.value)}
//                 required
//               >
//                 <option value="">Code</option>
//                 {countryCodeOptions}
//               </select>
//               <input
//                 type="tel"
//                 className="phone-number-input"
//                 placeholder="Enter phone number"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 required
//               />
//             </div>
//           </div>
//         </div>

//         <div className="group">
//           <div className="input-group">
//             <label>
//               Educational Qualification <span className="required">*</span>
//             </label>
//             <select
//               className="select-dropdown"
//               value={educationLevel}
//               onChange={(e) => setEducationLevel(e.target.value)}
//               required
//             >
//               <option value="">Select</option>
//               <option value="BSc">BSc</option>
//               <option value="BS">BS</option>
//               <option value="MSc">MSc</option>
//               <option value="MS">MS</option>
//               <option value="PhD">PhD</option>
//               <option value="MPhil">MPhil</option>
//               <option value="Postdoctoral Research">
//                 Postdoctoral Research
//               </option>
//               <option value="Assistant Professor">Assistant Professor</option>
//               <option value="Associate Professor">Associate Professor</option>
//               <option value="Professor">Professor</option>
//               <option value="Others">Others</option>
//             </select>
//           </div>

//           <div className="input-group">
//             <label>
//               Affiliated University (optional)
//             </label>
//             <input
//               type="text"
//               value={affiliation}
//               onChange={(e) => setAffiliation(e.target.value)}
//             />
//           </div>

//           <div className="input-group">
//             <label>
//               Password <span className="required">*</span>
//             </label>
//             <input
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//             />
//           </div>

//           <div className="input-group">
//             <label>
//               Confirm Password <span className="required">*</span>
//             </label>
//             <input
//               type="password"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               required
//             />
//           </div>
//         </div>
//       </form>
//       <ToastContainer />
//       <button type="submit" className="submit-btn">
//           Signup
//         </button>
//     </div>
//   );
// };

// export default SignUpForm;

// above is the old code.
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeTitle } from "../utils/ChangeTitle";
import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/build/css/intlTelInput.css";

const SignUpForm = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  useEffect(() => {
    changeTitle("Signup | Scitome");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName &&
      lastName &&
      email &&
      isValid &&
      educationLevel &&
      password === confirmPassword
    ) {
      onSubmit({
        firstName,
        lastName,
        email,
        phoneNumber,
        educationLevel,
        affiliation,
        password,
      });
    } else {
      toast.error("Please fill in all fields and make sure passwords match.");
    }
  };

  return (
    <div className="modal-signup-container">
      <h2>Create an account</h2>
      <p className="text-desc">Enter your email to sign up for this app</p>
      <form className="signupmodal" onSubmit={handleSubmit}>
        <div className="group">
          <div className="input-group">
            <label>
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>
              Email ID <span className="required">*</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>
              Phone Number <span className="required">*</span>
            </label>
            <IntlTelInput
              
              initialValue={phoneNumber}
              onChangeNumber={setPhoneNumber}
              onChangeValidity={setIsValid}
              onChangeErrorCode={setErrorCode}
              initOptions={{
                initialCountry: "in",
                loadUtils: () => import("intl-tel-input/build/js/utils.js"),
              }}
              className="intl-tel-input-custom"
            />
          </div>
        </div>

        <div className="group">
          <div className="input-group">
            <label>
              Educational Qualification <span className="required">*</span>
            </label>
            <select
              className="select-dropdown"
              value={educationLevel}
              onChange={(e) => setEducationLevel(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="BSc">BSc</option>
              <option value="BS">BS</option>
              <option value="MSc">MSc</option>
              <option value="MS">MS</option>
              <option value="PhD">PhD</option>
              <option value="MPhil">MPhil</option>
              <option value="Postdoctoral Research">
                Postdoctoral Research
              </option>
              <option value="Assistant Professor">Assistant Professor</option>
              <option value="Associate Professor">Associate Professor</option>
              <option value="Professor">Professor</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div className="input-group">
            <label>
              Affiliated University (optional)
            </label>
            <input
              type="text"
              value={affiliation}
              onChange={(e) => setAffiliation(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label>
              Password <span className="required">*</span>
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>
              Confirm Password <span className="required">*</span>
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
      </form>
      <ToastContainer />
      <button type="submit" className="submit-btn">
        Signup
      </button>
    </div>
  );
};

export default SignUpForm;

