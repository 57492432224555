// import React, { useRef } from 'react';
// import { motion, useScroll, useTransform } from 'framer-motion';
// import '../../Styles/about.css'


// const AboutPage = () => {
//   return (
//     <div className="about-page">
//       <motion.div 
//         className="about-container"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 1 }}
//       >
//         <motion.header 
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1, ease: "easeOut" }}
//           className="about-header"
//         >
//           <div className="header-content">
//             <h1>SciTome Publishing House</h1>
//             <p>By researchers, of researchers, for researchers</p>
//           </div>
//         </motion.header>

//         <div className="about-grid">
//           <motion.div 
//             className="mission-section"
//             initial={{ opacity: 0, x: -100 }}
//             whileInView={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.8, delay: 0.2 }}
//             viewport={{ once: true }}
//           >
//             <div className="section-icon">🔬</div>
//             <h2>Mission Statement</h2>
//             <p>At SciTome Publishing House, our mission is to reshape scientific publishing by making research accessible, affordable, and impactful. We strive to empower researchers, students, and the public with open access to high-quality, thoroughly reviewed scientific knowledge. Through innovation in AI-driven review systems and transparent processes, we are dedicated to creating a publishing platform where speed, integrity, and inclusivity drive scientific discovery.</p>
//           </motion.div>

//           <motion.div 
//             className="origin-section"
//             initial={{ opacity: 0, x: 100 }}
//             whileInView={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.8, delay: 0.4 }}
//             viewport={{ once: true }}
//           >
//             <div className="section-icon">🌐</div>
//             <h2>How it started</h2>
//             <p>SciTome Publishing House was born out of our shared frustration with the challenges in the current scientific publishing system. From poor-quality research and biases against scientists from India and other developing countries to the lack of open access and journals prioritizing commercial interests over science, these systemic issues hinder progress and accessibility. As researchers with expertise in molecular biology, genomics, and data science, we came together to reimagine the system. We started by designing a unique format to make scientific content concise, engaging, and accessible. Alongside this, we built a solid editorial infrastructure that incentivizes reviewers and editors, significantly shortens review times, and ensures a thorough evaluation process. Our model brings in multiple reviewers for each paper, fostering diverse insights and rigorous commentary to uphold the integrity of the research. At SciTome, we aim to transform publishing into a transparent, inclusive, and efficient process, ensuring research is not only shared but also deeply understood and trusted by scientists and the public alike.</p>
//           </motion.div>
//         </div>

//         <motion.div 
//           className="technology-section"
//           initial={{ opacity: 0, scale: 0.8 }}
//           whileInView={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 1, type: "spring", stiffness: 50 }}
//           viewport={{ once: true }}
//         >
//           <h2>Company Overview</h2>
//           <p>SciTome Publishing House is an innovative open-access publishing company committed to transforming how scientific knowledge is reviewed, published, and accessed. We aim to redefine scholarly publishing by making research more affordable, accessible, and impactful for the scientific community and the public alike. Recognizing the limitations of conventional academic publishing—such as high fees, long publication times, and limited accessibility—SciTome combines advanced technology with a transparent, efficient publishing model to meet the evolving needs of researchers and readers worldwide.</p>
//         </motion.div>

//         <motion.div 
//           className="apsara-section"
//           initial={{ opacity: 0 }}
//           whileInView={{ opacity: 1 }}
//           transition={{ duration: 1 }}
//           viewport={{ once: true }}
//         >
//           <h2></h2>
//           {/* <p>Our unique approach centers around APSARA (AI-Powered Scholarly Article Review Automation), an AI-driven system designed to expedite the peer review process by delivering initial feedback within minutes. By blending artificial intelligence with a robust, human-centered peer review infrastructure, we can deliver high-quality, thorough evaluations that accelerate publication timelines without compromising quality. Additionally, our format prioritizes readability and accessibility, incorporating AI summarization tools and multilingual support, making scientific insights accessible to a broader audience.</p> */}
//         </motion.div>

//         <motion.div 
//           className="commitment-section"
//           initial={{ opacity: 0 }}
//           whileInView={{ opacity: 1 }}
//           transition={{ duration: 1 }}
//           viewport={{ once: true }}
//         >
//           <p>In addition to our technical innovations, SciTome is built on a commitment to supporting the academic community. We offer an affordable, transparent fee structure, actively create opportunities for scientists and students to participate in the peer-review process, and maintain open access to all published content, ensuring that groundbreaking research reaches a wider audience without paywalls. Our publication formats and marketing strategies extend beyond traditional academic reach, providing authors with greater visibility, readers with comprehensible research, and the public with insights into impactful science.</p>
//         </motion.div>
//       </motion.div>
//     </div>
//   );
// };

// export default AboutPage;
import React from "react";
import { motion } from "framer-motion";
import "../../Styles/about.css";

const AboutPage = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div className="about-container">
      <motion.h1
        className="about-title"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        Our Mission
      </motion.h1>
      <motion.h1
        className="about-title-desc"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        By researchers, of researchers, for researchers
      </motion.h1>
      
      <motion.p
        className="about-text"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ delay: 0.2 }}
      >
       At SciTome Publishing House, our mission is to reshape scientific publishing by making
research accessible, affordable, and impactful. We strive to empower researchers, students,
and the public with open access to high-quality, thoroughly reviewed scientific knowledge.
Through innovation in AI-driven review systems and transparent processes, we are
dedicated to creating a publishing platform where speed, integrity, and inclusivity drive
scientific discovery.
      </motion.p>

      <section className="about-section">
        <motion.h2
          className="about-subtitle"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.4 }}
        >
          How It Started
        </motion.h2>
        <motion.p
          className="about-text"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.6 }}
        >
          SciTome Publishing House was born out of our shared frustration with the challenges in the
current scientific publishing system. From poor-quality research and biases against scientists
from India and other developing countries to the lack of open access and journals prioritizing
commercial interests over science, these systemic issues hinder progress and accessibility.
As researchers with expertise in molecular biology, genomics, and data science, we came
together to reimagine the system. We started by designing a unique format to make scientific
content concise, engaging, and accessible. Alongside this, we built a solid editorial
infrastructure that incentivizes reviewers and editors, significantly shortens review times, and
ensures a thorough evaluation process. Our model brings in multiple reviewers for each
paper, fostering diverse insights and rigorous commentary to uphold the integrity of the
research.
At SciTome, we aim to transform publishing into a transparent, inclusive, and efficient
process, ensuring research is not only shared but also deeply understood and trusted by
scientists and the public alike.
        </motion.p>
        <motion.p
          className="about-text"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.8 }}
        >
          With expertise in molecular biology, genomics, and data science, we
          developed an innovative system combining AI-driven reviews and a
          diverse editorial board, ensuring high-quality publications.
        </motion.p>
      </section>

      <section className="about-section">
        <motion.h2
          className="about-subtitle"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 1 }}
        >
          Company Overview
        </motion.h2>
        <motion.p
          className="about-text"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 1.2 }}
        >
         <b>SciTome Publishing House</b> is an innovative open-access publishing company committed
to transforming how scientific knowledge is reviewed, published, and accessed. We aim to
redefine scholarly publishing by making research more affordable, accessible, and impactful
for the scientific community and the public alike. Recognizing the limitations of conventional
academic publishing—such as high fees, long publication times, and limited
accessibility—SciTome combines advanced technology with a transparent, efficient
publishing model to meet the evolving needs of researchers and readers worldwide.
Our unique approach centers around <b>APSARA (AI-Powered Scholarly Article Review
Automation)</b>, an AI-driven system designed to expedite the peer review process by
delivering initial feedback within minutes. By blending artificial intelligence with a robust,
human-centered peer review infrastructure, we can deliver high-quality, thorough evaluations
that accelerate publication timelines without compromising quality. Additionally, our format
prioritizes readability and accessibility, incorporating AI summarization tools and multilingual
support, making scientific insights accessible to a broader audience.
        </motion.p>
        <motion.p
          className="about-text"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 1.4 }}
        >
          Our unique APSARA system (AI-Powered Scholarly Article Review
          Automation) accelerates reviews and enhances accuracy. We also ensure
          affordability, accessibility, and multilingual support for a broader
          global audience.
        </motion.p>
      </section>

      <section className="about-section">
        <motion.p
          className="about-text"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 1.6 }}
        >
          In addition to our technical innovations, SciTome is built on a commitment to supporting the
academic community. We offer an affordable, transparent fee structure, actively create
opportunities for scientists and students to participate in the peer-review process, and
maintain open access to all published content, ensuring that groundbreaking research
reaches a wider audience without paywalls. Our publication formats and marketing
strategies extend beyond traditional academic reach, providing authors with greater visibility,
readers with comprehensible research, and the public with insights into impactful science.
        </motion.p>
      </section>
    </div>
  );
};

export default AboutPage;

