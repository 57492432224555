import React, { useEffect, useState } from 'react';
import '../Styles/populartopics.css'
import { changeTitle } from '../../utils/ChangeTitle';

const PopularTopics = () => {

    useEffect(()=>{
        changeTitle("Popular Topics")
    })
    const topics = [
        { 
          id: 1, 
          name: "React", 
          image: "/images/ai.jpg", 
          filterImage: "/api/placeholder/24/24", 
          description: "Modern frontend development with React", 
          votes: 125 
        },
        { 
          id: 2, 
          name: "Node.js", 
          image: "/images/cs.jpg", 
          filterImage: "/api/placeholder/24/24", 
          description: "Server-side JavaScript runtime", 
          votes: 98 
        },
        { 
          id: 3, 
          name: "Python", 
          image: "/images/ai.jpg", 
          filterImage: "/api/placeholder/24/24", 
          description: "General-purpose programming language", 
          votes: 156 
        },
        { 
          id: 4, 
          name: "DevOps", 
          image: "/images/cs.jpg", 
          filterImage: "/api/placeholder/24/24", 
          description: "Development operations and automation", 
          votes: 87 
        }
      ];
      
      const filters = [
        { id: 1, name: "Programming", image: "/images/cs.jpg" },
        { id: 2, name: "Biology", image: "/images/bio.jpg" },
        { id: 4, name: "AI/ML", image: "/images/ai.jpg" },
        { id: 5, name: "Neuro Science", image: "/images/neuro.jpg" },
        { id: 6, name: "Database", image: "/images/bio.jpg" },
        { id: 7, name: "Security", image: "/images/cs.jpg" },
        { id: 8, name: "UI/UX", image: "/images/cs.jpg" },
        { id: 9, name: "Neuro Science", image: "/images/neuro.jpg" },
        { id: 10, name: "Database", image: "/images/bio.jpg" },
        { id: 11, name: "Security", image: "/images/cs.jpg" },
        { id: 12, name: "UI/UX", image: "/images/cs.jpg" },
        { id: 13, name: "Database", image: "/images/bio.jpg" },
        { id: 14, name: "Security", image: "/images/cs.jpg" },
        { id: 15, name: "UI/UX", image: "/images/cs.jpg" }
      ];
      
  const [topicsData, setTopicsData] = useState(topics.map(topic => ({
    ...topic,
    upvoted: false,
    downvoted: false
  })));
  const [activeFilter, setActiveFilter] = useState(null);

  const handleVote = (topicId, isUpvote) => {
    setTopicsData(prevTopics =>
      prevTopics.map(topic => {
        if (topic.id === topicId) {
          // If already voted in this direction, return unchanged
          if ((isUpvote && topic.upvoted) || (!isUpvote && topic.downvoted)) {
            return topic;
          }

          // If voted in opposite direction, remove that vote first
          let voteChange = 1;
          if (isUpvote && topic.downvoted) {
            voteChange = 2; // +1 to remove downvote, +1 for new upvote
          } else if (!isUpvote && topic.upvoted) {
            voteChange = -2; // -1 to remove upvote, -1 for new downvote
          }

          return {
            ...topic,
            votes: topic.votes + (isUpvote ? voteChange : -voteChange),
            upvoted: isUpvote,
            downvoted: !isUpvote
          };
        }
        return topic;
      })
    );
  };

  return (
    <>
      
      <div className="topics-container">
        <div className="filter-container">
          {filters.map(filter => (
            <div
              key={filter.id}
              className={`filter-box ${activeFilter === filter.id ? 'active' : ''}`}
              onClick={() => setActiveFilter(filter.id === activeFilter ? null : filter.id)}
            >
              <img src={filter.image} alt={filter.name} />
              <span>{filter.name}</span>
            </div>
          ))}
        </div>

        <div className="topics-grid">
          {topicsData.map(topic => (
            <div key={topic.id} className="topic-card">
              <img src={topic.image} alt={topic.name} className="topic-image" />
              <div className="topic-header">
                <h3 className="topic-title">{topic.name}</h3>
                <div className="voting-container">
                  <button
                    className={`vote-button ${topic.upvoted ? 'voted' : ''}`}
                    onClick={() => handleVote(topic.id, true)}
                    disabled={topic.upvoted}
                  >
                    ▲
                  </button>
                  <span className="vote-count">{topic.votes}</span>
                  <button
                    className={`vote-button ${topic.downvoted ? 'voted' : ''}`}
                    onClick={() => handleVote(topic.id, false)}
                    disabled={topic.downvoted}
                  >
                    ▼
                  </button>
                </div>
              </div>
              <p className="topic-description">{topic.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PopularTopics;
