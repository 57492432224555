import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/scicheader.css";

const SciHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const [linePosition, setLinePosition] = useState({ left: 0, width: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const options = ["Select Item", "Search by keyword", "Search by Category"];

  // Click handlers
  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    const linkElement = document.getElementById(link);
    setLinePosition({
      left: linkElement.offsetLeft,
      width: linkElement.offsetWidth,
    });
  };

  return (
    <header className="sci-header">
      <div className="sci-container">
        <div className="search-bar-sci">
          <div className="sci-input-group dropdown">
            <div className="sci-select-wrapper">
              <input
                type="text"
                readonly
                className="sci-select"
                placeholder="Select Option"
                value={selectedValue}
                onClick={toggleDropdown}
              />
              <span className="dropdown-icon"></span>
              {isOpen && (
                <div className={`sci-dropdown-list ${isOpen ? "active" : ""}`}>
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="sci-dropdown-option"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div class="sci-input-group">
            <input
              type="text"
              placeholder="Search Keywords"
              className="sci-input"
            />
            <span className="search-icon"></span>
          </div>
        </div>
        {!isMobile && (
          <nav className="sci-nav">
            <Link
              id="home"
              to="/scicom/home"
              className={`sci-nav-link ${
                activeLink === "home" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("home")}
            >
              HOME
            </Link>
            <Link
              id="scipop"
              to="/scicom/sci-pop"
              className={`sci-nav-link ${
                activeLink === "scipop" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("scipop")}
            >
              SCIPOP
            </Link>
            <Link
              id="books"
              to="#"
              className={`sci-nav-link ${
                activeLink === "books" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("books")}
            >
              BOOKS
            </Link>
            <Link
              id="editors-picks"
              to="/scicom/editor-pick"
              className={`sci-nav-link ${
                activeLink === "editors-picks" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("editors-picks")}
            >
              EDITOR'S PICKS
            </Link>
            <Link
              id="help"
              to="#"
              className={`sci-nav-link ${
                activeLink === "help" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("help")}
            >
              HELP
            </Link>
            <Link
              id="your-library"
              to="/scicom/library"
              className={`sci-nav-link ${
                activeLink === "your-library" ? "sci-nav-link-active" : ""
              }`}
              onClick={() => handleLinkClick("your-library")}
            >
              YOUR LIBRARY
            </Link>

            <div className="sci-divider"></div>
            <div
              className="sci-line"
              style={{
                left: `${linePosition.left}px`,
                width: `${linePosition.width}px`,
              }}
            ></div>
          </nav>
        )}

        {isMobile && (
          <div className="sci-mobile-nav">
            <a href="#" className="sci-mobile-link">
              HOME
            </a>
            <a href="#" className="sci-mobile-link">
              SCIPOP
            </a>
            <a href="#" className="sci-mobile-link">
              BOOKS
            </a>
            <a href="#" className="sci-mobile-link">
              EDITOR'S PICKS
            </a>
            <a href="#" className="sci-mobile-link">
              HELP
            </a>
            <a href="#" className="sci-mobile-link">
              YOUR LIBRARY
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

export default SciHeader;
