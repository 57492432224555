import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../Styles/submissionportal.css';
import ProgressButton from './ProgressButton';
import {steps} from '../data/Steps'
const SubmissionMain = () => {
  const [progressStatus, setProgressStatus] = useState({
    currentStep: 0,
    completedSteps: [0, 1],
  });

  const [formData, setFormData] = useState({
    letterBody: '',
    questionnaire: '',
    abstract: '',
    graphicalAbstractFile: null,
    graphicalAbstractPreview: null,
  });



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({
          ...prev,
          graphicalAbstractFile: file,
          graphicalAbstractPreview: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDiscard = () => {
    setFormData((prev) => ({
      ...prev,
      graphicalAbstractFile: null,
      graphicalAbstractPreview: null,
    }));
  };

  const getButtonClass = (index) => {
    if (index < progressStatus.currentStep) return 'completed';
    if (index === progressStatus.currentStep) return 'in-progress';
    return 'disabled';
  };

  const handleSaveDraft = () => {
    toast.info('Draft saved!');
    console.log('Draft saved:', formData);
  };

  const handleSubmit = () => {
    toast.success('Form submitted!');
    console.log('Form submitted:', formData);
  };

  return (
    <div className="progress-banner-container">
      <ProgressButton
        steps={steps}
        currentStep={progressStatus.currentStep}
        getButtonClass={getButtonClass}
      />

      <div className="content-sections">
        <section className="letter-body">
          <h2>Letter Body</h2>
          <textarea
            name="letterBody"
            value={formData.letterBody}
            onChange={handleInputChange}
            placeholder="Write your letter body here..."
          />
        </section>

        <section className="questionnaire">
          <h2>Questionnaire</h2>
          <textarea
            name="questionnaire"
            value={formData.questionnaire}
            onChange={handleInputChange}
            placeholder="Answer the questionnaire here..."
          />
        </section>

        <section className="abstract">
          <h2>Abstract</h2>
          <textarea
            name="abstract"
            value={formData.abstract}
            onChange={handleInputChange}
            placeholder="Write your abstract here..."
          />
        </section>

        <section className="graphical-abstract">
          <h2>Graphical Abstract</h2>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
          {formData.graphicalAbstractPreview && (
            <div className="preview-container">
              <h3>Preview:</h3>
              <img
                src={formData.graphicalAbstractPreview}
                alt="Graphical Abstract Preview"
                className="graphical-abstract-preview"
              />
              <button
                type="button"
                className="discard-button"
                onClick={handleFileDiscard}
              >
                {formData.graphicalAbstractFile?.name} <span>&#10005;</span>
              </button>
            </div>
          )}
        </section>
      </div>

      <div className="form-footer">
        <button className="save-draft-button" onClick={handleSaveDraft}>
          Save as Draft
        </button>
        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SubmissionMain;
