import React, { useEffect } from 'react'
import {changeTitle} from '../../utils/ChangeTitle'
const LibraryScicome = () => {

  useEffect(()=>{
    changeTitle(" Scicom | Library")
  },[])
  
  return (
    <div>LibraryScicome</div>
  )
}

export default LibraryScicome