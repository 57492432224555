import React, { useEffect } from 'react'
import SciHeader from './Components/SciHeader'
import { Outlet } from 'react-router-dom'
import {changeTitle} from '../utils/ChangeTitle'
const ScicomHome = () => {
  useEffect(()=>{
    changeTitle(" Scicom | Home")
  },[])
  return (
    <div className='flex-col justify-around items-center'>
        <SciHeader/>
        <Outlet/>
    </div>
  )
}

export default ScicomHome