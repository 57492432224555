import React, { useEffect } from 'react'
import {changeTitle} from '../../utils/ChangeTitle'
const EditorsPickPage = () => {
  useEffect(()=>{
    changeTitle(" Scicom | Editor's Pick")
  },[])
  
  return (
    <div>EditorsPickPage</div>
  )
}

export default EditorsPickPage