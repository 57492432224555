import React from 'react';
import PropTypes from 'prop-types';


const ProgressButton = ({ steps, currentStep, getButtonClass }) => {
  return (
    <div className="progress-buttons">
      {steps.map((step, index) => (
        <button
          key={index}
          className={`progress-button ${getButtonClass(index)}`}
          disabled={index > currentStep}
        >
          {step}
        </button>
      ))}
    </div>
  );
};

ProgressButton.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  getButtonClass: PropTypes.func.isRequired,
};

export default ProgressButton;
