import React from 'react'
import '../../Styles/main.css'
import { motion } from "framer-motion";
const Services = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 1, // Delay between each child appearance
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 30 },
        show: { opacity: 1, y: 0 },
    };
  return (
    <div className="servives-home">
        <h1 className="service-header-h">WHAT WE BRING TO THE TABLE</h1>
        <motion.div
            className="service-content-container"
            variants={containerVariants}
            initial="hidden"
            whileInView="show"
            viewport={{once:true}}
        >
            <motion.div className="content-service" variants={itemVariants}>
                <h2 className="content-header-service">KNOWLEDGE FOR ALL</h2>
                <p className="content-desc-service">We believe all scientific knowledge should be open access.</p>
            </motion.div>

            <motion.div className="content-service" variants={itemVariants}>
                <h2 className="content-header-service">COMPENSATION FOR WORK</h2>
                <p className="content-desc-service">We believe that all contributions to the progress of science must be
                    recognised and duly compensated, be it from authors, reviewers, or editors.</p>
            </motion.div>

            <motion.div className="content-service" variants={itemVariants}>
                <h2 className="content-header-service">AI-ASSISTED REVIEWING</h2>
                <p className="content-desc-service">AI is here to stay, and we're here to use it to the best of its (and our)
                    abilities.</p>
            </motion.div>
        </motion.div>

    </div>
  )
}

export default Services