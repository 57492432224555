import React from 'react';
import { motion } from 'framer-motion';
import '../Styles/Scicards.css';


export const Card = ({ title, description, image }) => (
  <motion.div
    className="card"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    initial={{ opacity: 0, y: 50 }}
    animate={{
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.6, // Higher bounce for more "bouncy" effect
        duration: 0.8,
      },
    }}
  >
    <img src={image} alt={title} className="card-image" />
    <div className="card-content">
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
  </motion.div>
);

const ViewMoreCard = ({ onClick }) => (
  <motion.div
    className="card view-more-card"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    initial={{ opacity: 0, y: 50 }}
    animate={{
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        bounce: 1,
        duration: 1,
      },
    }}
  >
    <button className="view-more-btn" onClick={onClick}>
      View More
    </button>
  </motion.div>
);

const ResponsiveCardLayout = ({cardData}) => {
  const handleViewMore = () => {
    alert('View More clicked!');
  };

  return (
    <div className="cards-wrapper">
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          image={card.image}
        />
      ))}
      <ViewMoreCard onClick={handleViewMore} />
    </div>
  );
};

export default ResponsiveCardLayout;
