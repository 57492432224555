import React, { useState } from "react";
import "../../Styles/jobalerts.css";
import { useNavigate } from "react-router-dom";

const ProfileSection = () => {
  const navigate = useNavigate()
  const [pendingOpen, setPendingOpen] = useState(false);
  const [completedOpen, setCompletedOpen] = useState(false);

  return (
    <div className="ja-job-alerts-container">
      {/* Header */}
      <div className="ja-header">
        <h1 className="ja-title">JOB ALERTS</h1>
        <button onClick={()=>{navigate('/signup')}} className="ja-notification-button">
          Sign up for email notifications!
          <span className="ja-bell-icon">🔔</span>
        </button>
      </div>

      {/* Pending Jobs Section */}
      <div className="ja-job-section">
        <div
          className="ja-job-header"
          onClick={() => setPendingOpen(!pendingOpen)}
        >
          <h2 className="ja-job-title">PENDING JOBS</h2>
          <span className="ja-dropdown-icon">{pendingOpen ? "▲" : "▼"}</span>
        </div>
        {pendingOpen && (
          <div className="ja-job-content">
            <p className="ja-no-jobs">No pending jobs</p>
          </div>
        )}
      </div>

      {/* Completed Jobs Section */}
      <div className="ja-job-section">
        <div
          className="ja-job-header"
          onClick={() => setCompletedOpen(!completedOpen)}
        >
          <h2 className="ja-job-title">COMPLETED JOBS</h2>
          <span className="ja-dropdown-icon">{completedOpen ? "▲" : "▼"}</span>
        </div>
        {completedOpen && (
          <div className="ja-job-content">
            <p className="ja-no-jobs">No completed jobs</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSection;
