import React from "react";
// import Main from "../Main/Main";
import Contact from "../ContactUs/Contact";
import PolicyMain from "../Privacy/PolicyMain";
import { Route, Routes } from "react-router-dom";
import EditorialPage from "../Privacy/EditorialPage";
import AuthorshipPage from "../Privacy/AuthorshipPage";
import SignUpForm from "../Header/SignupForm";
import Home from "../Main/Home";
import Gallery from "../Gallery/Gallery";
import CardDetail from "../Gallery/CardDetail";
import ScicomHome from "../../components/Scicom/ScicomHome";
import SciHomePage from "../Scicom/Components/SciHomePage";
import Scipop from "../Scicom/Components/Scipop";
import LibraryScicome from "../Scicom/Components/LibraryScicome";
import EditorsPickPage from "../Scicom/Components/EditorsPickPage";
import AccountDetails from "../Accounts/AccountDetails";
import AccountDashboard from "../Accounts/AccountDashboard";
import ProfileSection from "../Accounts/ProfileSection";
import AboutPage from "../About Us/AboutPage";
import SubmissionMain from "../Submissions/SubmissionMain";
import SubmissionAcceptance from "../Submissions/SubmissionAcceptance";
import PopularTopics from "../Scicom/Components/PopularTopics";
const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<PolicyMain />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/policy" element={<PolicyMain />}>
          <Route index element={<EditorialPage />} />
          <Route path="editorial-policies" element={<EditorialPage />} />
          <Route path="authorship-policies" element={<AuthorshipPage />} />
        </Route>
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/topics/:id" element={<CardDetail />} />
        <Route path="/account" element={<AccountDetails />} />
        <Route path="/about" element={<AboutPage />} />

        <Route path="/scicom" element={<ScicomHome />}>
          <Route index element={<SciHomePage />} />
          <Route path="home" element={<SciHomePage />} />
          <Route path="sci-pop" element={<Scipop />} />
          <Route path="library" element={<LibraryScicome />} />
          <Route path="editor-pick" element={<EditorsPickPage />} />
        </Route>
        <Route path="scicom/popular-topics" element={<PopularTopics/>} />

        <Route path="/accounts/dashboard" element={<AccountDashboard />}>
          <Route path='profile' element={<ProfileSection/>} />
          
        </Route>
        
          <Route path='/submission' element={<SubmissionMain/>} />
          <Route path='/submission/acceptance' element={<SubmissionAcceptance/>} />
        <Route>

        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
