import React, { useEffect } from 'react'
import {changeTitle} from '../../utils/ChangeTitle'
const Scipop = () => {
  useEffect(()=>{
    changeTitle(" Scicom | SciPop")
  },[])
  
  return (
    <div>Scipop</div>
  )
}

export default Scipop