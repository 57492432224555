import React, { useEffect } from "react";
import { changeTitle } from "../../utils/ChangeTitle";
import "../Styles/SciHomePage.css";
import ResponsiveCardLayout, { Card } from "./SciCards";
import { motion } from "framer-motion";
const placeholderImage = '/images/bannerimage.jpg';

const SciHomePage = () => {
  useEffect(() => {
    changeTitle(" Scicom | Home");
  }, []);
  const topics = [
    { title: "CELL BIOLOGY", image: "/api/placeholder/300/200" },
    { title: "NEUROSCIENCE", image: "/api/placeholder/300/200" },
    { title: "DEVELOPMENTAL BIOLOGY", image: "/api/placeholder/300/200" },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };
 
  const cardData = [
    {
      title: 'Card Title 1',
      description: 'A brief description of the first card\'s content goes here.',
      image: placeholderImage,
    },
    {
      title: 'Card Title 2',
      description: 'A brief description of the second card\'s content goes here.',
      image: placeholderImage,
    },
  ];
  // const hoverVariants = {
  //   hover: {
  //     y: -8,
  //     transition: {
  //       type: "spring",
  //       stiffness: 400,
  //       damping: 25,
  //     },
  //   },
  // };
  return (
    <div className="sci-home-page">
      {/* Intro section  */}
      <div className="intro-section">
        <motion.div
          className="sci-description"
          initial={{ x: 100, opacity: 0 }}
          style={{
            backgroundImage: "url('/images/design-pad-r.svg')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: "spring", duration: 3.5 }}
        >
          <h2 className="heading">FEATURED ARTICLES</h2>
          <p className="desc">Read more about this week's headliner</p>
        </motion.div>
        <ResponsiveCardLayout cardData={cardData} />
      </div>
      {/* Book of the Month  */}
      <div className="book-of-month">
        <Card
          title="Book Title"
          description="Description"
          image="/images/bannerimage.jpg"
        />
        <div className="shape-sci-book">
          <motion.div
            className="right-shape sci-description"
            style={{
              backgroundImage: "url('/images/design-pad-l.svg')",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ type: "spring", duration: 3.5 }}
          >
            <h2 className="heading" style={{ color: "#120621" }}>
              LATEST RESEARCH
            </h2>
            <p className="desc" style={{ color: "#5A378A" }}>
              Browse through our recent articles
            </p>
          </motion.div>
          <button className="add-to-lib">Add to your library</button>
        </div>
      </div>

            {/* Gallery */}


            <div className="intro-section">
        <motion.div
          className="sci-description"
          initial={{ x: 100, opacity: 0 }}
          style={{
            backgroundImage: "url('/images/design-pad-r.svg')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: "spring", duration: 3.5 }}
        >
          <h2 className="heading">GALLERY</h2>
          <p className="desc">******</p>
        </motion.div>
        <ResponsiveCardLayout cardData={cardData} />
      </div>


      {/*  Question of the day */}

      <div className="question-of-the-day">
      <h2 className="q-heading">QUESTION OF THE DAY</h2>
      <div className="content">
        {/* Left Box */}
        <motion.div
          className="left-box"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 30,
          }}
        >
          <p>
            A random science question in MCQ format so that we can get
            engagement.
          </p>
          <p>
            We can also have crosswords or wordles with science themes to get
            engagement and maintain streaks.
          </p>
        </motion.div>

        {/* Right Box */}
        <motion.div
          className="right-box"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 30,
          }}
        >
          <p>
            MEME /<br />
            FUNNY QUOTE
          </p>
        </motion.div>
      </div>
    </div>

    
      {/* Popular topics container  */}
      <div className="p-topic-container">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="q-heading"
        >
          POPULAR TOPICS
        </motion.h2>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="p-topic-grid"
        >
          {topics.map((topic) => (
            <motion.div
              key={topic.title}
              variants={itemVariants}
              whileHover="hover"
              className="p-topic-card-wrapper"
            >
              <div className="p-topic-card">
                <div className="p-topic-image-container">
                  <img
                    src={topic.image}
                    alt={topic.title}
                    className="p-topic-image"
                  />
                  <motion.div
                    className="p-topic-overlay"
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 0.2 }}
                    transition={{ duration: 0.2 }}
                  />
                </div>
                <motion.div
                  className="p-topic-content"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <h3 className="p-topic-heading">{topic.title}</h3>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default SciHomePage;
