import React, { useEffect,useState } from 'react';
import '../../Styles/accounts.css'
import { changeTitle} from '../utils/ChangeTitle'
import { ChevronUp, ChevronDown } from 'lucide-react';

// useEffect(()=>{
//     changeTitle("User Accounts")
//   },[])



const AccountDetails = () => {
    useEffect(()=>{
        changeTitle("User Accounts")
      },[])
  const [submissionProgress, setSubmissionProgress] = useState(70);
  const [showJobs, setShowJobs] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);

  return (
    <div className="acc-account-details-wrapper">
      <div className="acc-nav-wrapper">
        <div className="acc-nav-item">PUBLISH</div>
        <div className="acc-nav-item">CAREERS</div>
        <div className="acc-nav-item">JOBS</div>
      </div>
      <div className="acc-account-wrapper">
        <h2 className="acc-account-title">ACCOUNT: INR 30,000</h2>
        <div className="acc-submissions-wrapper">
          <h3 className="acc-section-title">Your submissions</h3>
          <div className="acc-submission-item">
            <div className="acc-submission-title">Title 1 ...</div>
            <div className="acc-submission-progress" style={{ width: `${submissionProgress}%` }}></div>
          </div>
          <div className="acc-submission-item">
            <div className="acc-submission-title">Title 2 ...</div>
            <div className="acc-submission-progress" style={{ width: `${submissionProgress}%` }}></div>
          </div>
        </div>
        <div className="acc-jobs-wrapper">
          <h3 className="acc-section-title" onClick={() => setShowJobs(!showJobs)}>
            Your Jobs{' '}
            {showJobs ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </h3>
          {showJobs && (
            <div className="acc-jobs-content">
              {/* Add your jobs content here */}
            </div>
          )}
        </div>
        <div className="acc-bookmarks-wrapper">
          <h3 className="acc-section-title" onClick={() => setShowBookmarks(!showBookmarks)}>
            Your Bookmarks{' '}
            {showBookmarks ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </h3>
          {showBookmarks && (
            <div className="acc-bookmarks-content">
              {/* Add your bookmarks content here */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;