import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../Styles/sidebar.css'

const AccountSideNavbar = () => {
  return (
    <div className='sidebar-container'>
        <input type="checkbox" name="" id="sidebar-active" />
        <label htmlFor="sidebar-active">
        <FontAwesomeIcon icon={faBars} className='burger-menu-icon' />
        </label>
        <div className="sidebar-links-container">
            <label htmlFor="sidebar-active">
            <FontAwesomeIcon icon={faXmark}  className='X-menu-icon' />
            </label>
            <Link to="/accounts/dashboard/profile" className="privacy-links">Your Profile</Link>
            <Link to="" className="privacy-links">Your Submissions</Link>
            <Link to="" className="privacy-links">BookMarks Library</Link>
            <Link to="" className="privacy-links">Scicomm</Link>
            <Link to="" className="privacy-links">Account Settings</Link>
            <Link to="" className="privacy-links">Help</Link>
            <Link to="" className="privacy-links">Logout</Link>
        </div>
    </div>
  )
}

export default AccountSideNavbar